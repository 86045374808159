.about {
    height: -webkit-calc(100dvh - 68px);
    width: 100dvw;
    background-color: var(--bgcolor);
    display: flex-column;
    justify-content: center;
}
.about-body{
    display: flex;
    justify-content: center;
    padding-top: 3%;
    align-items: center;
}

.about-text{
    font-family: 'Libre Caslon Text', serif;
}

.about-title{
    padding-top: 3%;
    padding-bottom: 5%;
    font-family: 'Libre Bodoni', serif;
    text-align: center;
    font-size: 8vmin;
    margin: 0;
}

.image{
    max-width: 32.5dvw;
    padding: 3dvh 3dvw 0;
}

.text-container{
    padding-right: 5dvb;
    font-size: 2dvb;
    width: 30%;
    height: 100%;
}

.link-item{
    font-family: 'Libre Caslon Display', serif;
    text-decoration: underline;
    color: var(--text);
    font-size: 2dvb;
    padding-inline-end: 15px;
}

@media screen and (max-width:600px){
    .about {
        height: min-content;
        width: 100dvw;
        background-color: var(--bgcolor);
        display: flex-column;
        justify-content: center;
    }
    .about-body{
        display: flex;
        justify-content: center;
        padding-top: 10%;
        padding-bottom: 18%;
        align-items: center;
    }
    .text-container{
        font-size: 2dvb;
        text-align: center;
        width: 75%;
        padding: 0;
        height: 100%;
    }
    .link-item{
        font-family: 'Libre Caslon Display', serif;
        text-decoration: underline;
        color: var(--text);
        font-size: 2dvb;
        padding-inline: 15px;
    }
    .image{
        padding: 0;
        max-width: 0dvw;
    }
}