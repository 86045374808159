.projects{
    height: -webkit-calc(100dvh - 68px);
    width: 100dvw;
    background-color: var(--bgcolor);
    display: flex-column;
    justify-content: center;
}

.projects-title{
    padding-top: 3%;
    padding-bottom: 5%;
    font-family: 'Libre Bodoni', serif;
    text-align: center;
    font-size: 8vmin;
    margin: 0;
}

.card-grid-container {
    padding: 75px 0 75px;
    display: flex;
    justify-content: center;
}
.card-grid{
    display: inline-grid;
    grid-template-columns: auto;
    row-gap: 25px;
    column-gap: 25px;
}

@media screen and (min-width: 900px) {
    .card-grid-container {
        padding: 75px 0 75px;
        display: flex;
        justify-content: center;
    }

    .card-grid{
        display: inline-grid;
        grid-template-columns: auto auto;
        row-gap: 25px;
        column-gap: 25px;
    }
}

@media screen and (min-width: 1200px) {
    .projects{
        height: calc(min-content + 124px);
        width: 100dvw;
        background-color: var(--bgcolor);
        display: flex-column;
        justify-content: center;
    }
    .card-grid-container {
        padding: 75px 0 75px;
        display: flex;
        justify-content: center;
    }

    .card-grid{
        display: inline-grid;
        grid-template-columns: auto auto auto;
        row-gap: 25px;
        column-gap: 25px;
    }
}

