.card{
    width: 35dvb;
    height: 48dvh;
    background-color: var(--accent2);
    padding: 1.5dvb;
}

.card-img {
    max-width: 100%;
    height: 25dvh;
    object-fit: cover;
}

.card h1{
    font-family: 'Libre Bodoni', serif;
    font-size: 3.5dvb;
    margin: 0;
}

.card-desc{
    padding-top: 10px;
}
.card p{
    font-size: 1.75dvb;
    font-family: 'Libre Caslon Text', serif;
    margin: 0;
    padding-bottom: 5px;
}

