.nav-header{
    position: sticky;
    top: 0;
    width: 100dvw;
    height: 68px;
    color: var(--text);
    font-family: 'Libre Caslon Display', serif;
    z-index: 2;
    background-color: var(--bgcolor);
}

.navbar{
    display: flex;
    justify-content: space-between;
    max-width: 65%;
    margin: auto;
    align-items: center;
    height: 100%;
}
.logo{
    font-size: 25px;
    cursor: pointer;
}

.nav-menu{
    display: flex;
    list-style: none;
}

.nav-item{
    font-size: 25px;
    padding-inline-start: 2rem;
}

.nav-item:hover{
    cursor: pointer;
    color:var(--accent1);
    transition: 0.5s;
}

@media screen and (max-width: 600px) {
    .logo {
        font-size: 0;
        padding: 0;
    }
    .nav-header{
        position: sticky;
        top: 0;
        width: 100dvw;
        height: 124px;
        color: var(--text);
        font-family: 'Libre Caslon Display', serif;
        z-index: 2;
        background-color: var(--bgcolor);
    }
    .navbar{
        flex-direction: column;
    }
    .nav-menu{
        padding: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        list-style: none;
    }
    .nav-item{
        padding: 0;
        font-size: 25px;
    }
}