.home{
    height: -webkit-calc(100dvh - 68px);
    background-color: var(--bgcolor);
    width: 100dvw;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-family: 'Libre Bodoni', serif;
    margin:0;
}

.circles {
    padding-top: 3.5%;
    align-items: baseline;
    align-self: center;
    display: flex;
    justify-content: center;
}
.text {
    position: absolute;
    display: flex-column;
    text-align: center;
    z-index: 1;
}
.desc {
    font-family: 'Libre Caslon Text', serif;
    font-size: 1.75vmax;
    font-style: italic;
    margin: 0;
}

.hi{
    font-size: 6.75vmax;
    margin: 0;
}

.pinkcirc {
    padding-right: 5%;
    padding-bottom: 16%;
    max-width:42%;
    width: 100%;
    height: auto;
}

.purplecirc {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 8%;
    width: 100%;
    height: auto;
    max-width: 30%;
}
/* 
@media screen and (max-width: 900px){
    .hi{
        font-size: 9dvb;
        margin: 0;
    }
} */

@media screen and (max-width: 600px){
    .hi{
        font-size: 55px;
        margin: 0;
    }
}