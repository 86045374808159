:root{
  --bgcolor: #F4ECEA;
  --text: #403744;
  --accent1: #BF838D;
  --accent2: #ECDCDC;
  --accent3: #958694;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bgcolor);
  color: var(--text);
}

body::-webkit-scrollbar{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&family=Libre+Caslon+Text:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&family=Libre+Caslon+Text:ital@1&display=swap');